import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget({ symbol }) {
  const container = useRef(null);
  const scriptLoadedRef = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !scriptLoadedRef.current) {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbol: symbol,
          width: "100%",
          height: "170",
          locale: "en",
          dateRange: "2M",
          colorTheme: "dark",
          isTransparent: true,
          autosize: true,
          largeChartUrl: "tradingview.com",
          chartOnly: false,
          noTimeScale: true
        });
        container.current.appendChild(script);
        scriptLoadedRef.current = true;
      }
    });

    if (container.current) {
      observer.observe(container.current);
    }

    return () => {
      if (container.current) {
        observer.unobserve(container.current);
        while (container.current.firstChild) {
          container.current.removeChild(container.current.firstChild);
        }
        scriptLoadedRef.current = false;
      }
    };
  }, [symbol]);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default memo(TradingViewWidget);
