import React from 'react';
import './SubsEmagazine.css';
import { useTranslation } from 'react-i18next'; // i18n import

const SubsEmagazine = () => {
  const { t } = useTranslation(); // Hook for translations

  return (
    <div className="subscription-container">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className='text-start'>{t('subsEmagazine.title')}</h2>
            <p className='text-start'>
              {t('subsEmagazine.description')}
            </p>
          </div>
          <div className="col-md-6">
            <form className="subscription-form">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.name_placeholder')} 
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.subject_placeholder')} 
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.phone_placeholder')} 
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <input 
                    type="email" 
                    className="form-control" 
                    placeholder={t('subsEmagazine.email_placeholder')} 
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <textarea 
                    className="form-control" 
                    placeholder={t('subsEmagazine.message_placeholder')} 
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <button type="submit" className="btn btn-danger">
                    {t('subsEmagazine.submit_button')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubsEmagazine;
