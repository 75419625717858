import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Offcanvas, Dropdown } from 'react-bootstrap';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Menu.css';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import WhatsApp from '../assets/Logos/whatsap-destek-hattı.png';
import { useTranslation } from 'react-i18next';
// SVG bayrak dosyaları
import trFlag from '../assets/Logos/turkish.svg';
import gbFlag from '../assets/Logos/english.svg';
import frFlag from '../assets/Logos/french.svg';

const Menu = () => {
  const { t, i18n } = useTranslation(); // i18n dil değişim işlemi
  const [show, setShow] = useState(false);
  const [placement, setPlacement] = useState('top');
  const [selectedLanguage, setSelectedLanguage] = useState('tr'); // Varsayılan dil Türkçe

  useEffect(() => {
    // Sayfa yüklendiğinde dil seçimi
    const savedLanguage = localStorage.getItem('selectedLanguage'); // LocalStorage'dan dil kontrolü
    const browserLanguage = navigator.language.split('-')[0]; // Tarayıcı dili
    const defaultLanguage = savedLanguage || (['tr', 'en', 'fr'].includes(browserLanguage) ? browserLanguage : 'tr');

    // Seçili dili ayarla
    setSelectedLanguage(defaultLanguage);
    i18n.changeLanguage(defaultLanguage);

    // Pencere boyutuna göre menü yerleşimi
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setPlacement('start'); // Mobile view
      } else {
        setPlacement('top'); // Desktop view
      }
    };

    handleResize(); // Set initial placement
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [i18n]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang); // i18n dil değişim işlemi
    localStorage.setItem('selectedLanguage', lang); // Seçilen dili LocalStorage'a kaydet
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            <Button variant="outline-primary" onClick={handleShow} style={{ marginTop: '-8px' }}>
              <FontAwesomeIcon icon={faBars} />
            </Button>
            <a className="nav-link" href="/Culture">{t('menu.culture')}</a>
            <a className="nav-link" href="/Science">{t('menu.science')}</a>
            <a className="nav-link" href="/Philosophy">{t('menu.philosophy')}</a>
            <a className="nav-link" href="/Sociology">{t('menu.sociology')}</a>
            <a className="nav-link" href="/Psychology">{t('menu.psychology')}</a>
            <a className="nav-link" href="/TourismHealth">{t('menu.tourismHealth')}</a>
            <a className="nav-link" href="/PersonalDevelopment">{t('menu.personalDevelopment')}</a>
            <a className="nav-link" href="/Research">{t('menu.research')}</a>
            <a className="nav-link" href="/Economy">{t('menu.economy')}</a>
            <a className="nav-link" href="/FinTech">{t('menu.fintech')}</a>
            <a className="nav-link" href="/Finance">{t('menu.finance')}</a>

            {/* Dil Seçimi Dropdown Menüsü */}
            <Dropdown className="nav-link" style={{ marginTop: '-8px' }}>
              <Dropdown.Toggle variant="light" id="dropdown-language">
                {/* Seçili olan dilin bayrağı */}
                <img 
                  src={selectedLanguage === 'en' ? gbFlag : selectedLanguage === 'fr' ? frFlag : trFlag} 
                  alt="Language Flag" 
                  style={{ width: '20px', height: '20px', marginRight: '5px' }}
                />
                {t('menu.language')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguageChange('tr')}>
                  <img src={trFlag} alt="Türkçe" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                  Türkçe
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange('en')}>
                  <img src={gbFlag} alt="English" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                  English
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange('fr')}>
                  <img src={frFlag} alt="Français" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                  Français
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement={placement}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row justify-content-center">
            <div className="col-md-3">
              <h6>{t('menu.corporateLinks')}</h6>
              <a className="nav-link" href="/">{t('menu.home')}</a>
              <a className="nav-link" href="/About">{t('menu.about')}</a>
              <a className="nav-link" href="/Blog">{t('menu.blog')}</a>
              <a className="nav-link" href="/Video">{t('menu.video')}</a>
              <a className="nav-link" href="/Contact">{t('menu.contact')}</a>
            </div>
            <div className="col-md-3">
              <h6>{t('menu.categories')}</h6>
              <a className="nav-link" href="/Culture">{t('menu.culture')}</a>
              <a className="nav-link" href="/Science">{t('menu.science')}</a>
              <a className="nav-link" href="/Philosophy">{t('menu.philosophy')}</a>
              <a className="nav-link" href="/Sociology">{t('menu.sociology')}</a>
              <a className="nav-link" href="/Psychology">{t('menu.psychology')}</a>
            </div>
            <div className="col-md-3">
              <h6>{t('menu.followUs')}</h6>
              <div className="social-icons">
                <a className="nav-link" href="https://www.facebook.com/fikirpanel/"><FontAwesomeIcon className='font-awesome-icon-menu' icon={faFacebook} /></a>
                <a className="nav-link" href="https://www.instagram.com/fikirpanel/"><FontAwesomeIcon className='font-awesome-icon-menu' icon={faInstagram} /></a>
                <a className="nav-link" href="https://www.youtube.com/channel/UCYkJc78JYubWTZ-qlwhLEfA"><FontAwesomeIcon className='font-awesome-icon-menu' icon={faYoutube} /></a>
              </div>
              <h6>{t('menu.contactUs')}</h6>
              <a className="nav-link" href="#whatsapp"><img src={WhatsApp} className='whatsapp-icon' alt="whatsapp"/></a>
              <a className="nav-link text-bold" href="#telefon">+90 850 241 0804</a>
            </div> 
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Menu;
