import React, { useState, useEffect } from 'react';
import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import TradingViewWidget from './TradingViewWidget';
import TradingViewCryptoWidget from './TradingViewCryptoWidget';
import YoutubeLogo from '../assets/Logos/Youtube_Logo_Fikir_Paneli.png';
import FikirPaneliLogo from '../assets/Logos/fikir_paneli_logo_white.png';
import CityClock from './CityClock';
import { useTranslation } from 'react-i18next';  // i18n import

// Import your data
import blogData from '../data/blogData'; 
import getCategoryContentData from '../data/getCategoryContentData'; 
import newsData from '../data/newsData';

const Header = () => {
    const { t, i18n } = useTranslation();  // Hook for translations
    const currentLang = i18n.language;  // Aktif dili alıyoruz
    const [weather, setWeather] = useState(null);
    const [location, setLocation] = useState({ lat: null, lon: null });
    const [searchQuery, setSearchQuery] = useState('');  // For storing search input
    const [searchResults, setSearchResults] = useState([]); // To store filtered results

    useEffect(() => {
        const fetchWeather = async (lat, lon) => {
            try {
                const apiKey = '886946f2a89ca9a891adfba7cca85ad7';
                const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${apiKey}`);
                const data = await response.json();
                setWeather(data);
            } catch (error) {
                console.error('Error fetching the weather data:', error);
            }
        };

        const getUserLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ lat: latitude, lon: longitude });
                    fetchWeather(latitude, longitude);
                }, (error) => {
                    console.error('Error getting location:', error);
                });
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };

        getUserLocation();
    }, []);

    const handleSearch = (event) => {
        event.preventDefault();
        
        // Tüm kartları toplamak için
        const allCategoryCards = Object.values(getCategoryContentData).flatMap(category => category.cards);
    
        // Blog, kategori kartları ve haber verilerini birleştir
        const allData = [...blogData, ...allCategoryCards, ...newsData];
    
        // Arama işlemi (case-insensitive) - İçerikler aktif dile göre kontrol edilecek
        const filteredResults = allData.filter(item => {
            // title ve summary'nin mevcut olup olmadığını kontrol ediyoruz
            const titleMatch = item.title?.[currentLang]?.toLowerCase().includes(searchQuery.toLowerCase());
            const summaryMatch = item.summary?.[currentLang]?.toLowerCase().includes(searchQuery.toLowerCase());
    
            // Slug'ı kontrol ederek de arama yapıyoruz
            const slugMatch = item.slug && item.slug.toLowerCase().includes(searchQuery.toLowerCase());
    
            return titleMatch || summaryMatch || slugMatch;
        });
        
        setSearchResults(filteredResults);
    };
    
    
    return (
        <div className='header-container'>
            <header className="header container">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-2 logo">
                            <a href="../"><img src={FikirPaneliLogo} alt="Fikir Paneli Logo" className="trading-view-logo" /></a>
                        </div>
                        <div className="col-md-2">
                           <a href="https://www.youtube.com/@Fikirpaneli" target="_blank" rel="noopener noreferrer"><img src={YoutubeLogo} alt="Fikir Paneli Logo" className="youtube-logo" /></a> 
                        </div>
                        <div className="col-md-3">
                            <a href="/subscribe" className="subscribe-link">{t('header.subscribe')}</a>
                        </div>
                        <div className="col-md-1 weather">
                            <i className="fas fa-cloud-sun"></i>
                            {weather && weather.main ? `${Math.round(weather.main.temp)}°C` : t('header.loading_weather')}
                        </div>
                        <div className="col-md-4">
                            <form className="d-flex search-form" onSubmit={handleSearch}>
                                <input 
                                    className="form-control me-2" 
                                    type="search" 
                                    placeholder={t('header.search_placeholder')} 
                                    aria-label="Search" 
                                    value={searchQuery} 
                                    onChange={(e) => setSearchQuery(e.target.value)} 
                                />
                                <button className="btn btn-outline-light" type="submit">
                                    <i className="fas fa-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </header>

            {/* Search results */}
            {searchResults.length > 0 && (
                <div className="search-results container mt-4">
                    <h5 className="mb-3 text-white">{t('header.search_results')}:</h5>
                    <div className="row">
                        {searchResults.map((result, index) => (
                            <div key={index} className="col-md-4 mb-4">
                                <div className="card h-100">
                                <img 
                                    src={result.image ? result.image : '/path/to/default-image.jpg'}  // Eğer image varsa onu kullan, yoksa varsayılan resmi kullan
                                    className="card-img-top" 
                                    alt={result.title?.[currentLang]}  // Eğer title yoksa boş bırakacak
                                />
                                    <div className="card-body">
                                        <h5 className="card-title">{result.title[currentLang]}</h5>
                                        <p className="card-text">{result.summary[currentLang]}</p>
                                        <a 
                                              href={result.category && result.category === 'Blog' 
                                                ? `/blog/${result.slug}`  // Eğer kategori blog ise
                                             : `/${result.category || 'blog'}/${result.slug}`}  // Eğer kategori tanımsız ise 'blog' kullan
                                               className="btn btn-primary"
                                        >
                                              {t('header.view')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* saati gösteren widget */}
            <div className="clock-container-header">
            <div className="">
                <CityClock />
            </div>
            </div>
            <div className='tradingview-widgets-container no-gutters'>
                
                <div className="tradingview-widgets ">
                    
                    <TradingViewWidget symbol="XU100" />
                    <TradingViewWidget symbol="FX:USDTRY" />
                    <TradingViewWidget symbol="FX:EURTRY" />
                    <TradingViewWidget symbol="FX:EURUSD" />
                    <TradingViewWidget symbol="OANDA:XAUUSD" />
                </div>
            </div>
            <div className='tradingview-crypto-widget-container' style={{ width: '100%' }}>
                <TradingViewCryptoWidget /> {/* Yeni kripto widget'ı buraya ekleyin */}
            </div>
        </div>
    );
}

export default Header;
